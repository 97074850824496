<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true" :model="searchForm">
          <el-form-item>
            <el-input v-model="searchForm.companyName" placeholder="关键词" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div>
              <el-button plain @click="handleCurrentChange(1)">查询</el-button>
              <!--            v-perm="['tools:douyin:keywords:import']"-->
              <div style="display: inline-block" v-perm="['tools:douyin:keywords:import']">
                <el-upload
                    action="#"
                    class="upload-demo"
                    :multiple="false"
                    :before-upload="handleBeforeUpload"
                    :limit="1">
                  <el-button icon="el-icon-upload" :loading="loadingImport">关键词导入</el-button>
                </el-upload>
                <el-button type="text" @click="handleDownloadTpl" style="margin-left: 10px">模版下载</el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="rows"
            v-loading="loading"
            style="width: 100%">
          <template slot="empty">
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column type="index" width="55" label="#"></el-table-column>
          <el-table-column prop="companyName" label="关键词"></el-table-column>
          <el-table-column prop="isHandle" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.isHandle ? '已处理' : '未处理' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10,20,50,100]"
            :page-size="size"
            :background="true"
            layout="total, sizes, prev, pager, next"
            :total="total">
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { DouyinLanvApi } from '@/api'

export default {
  name: 'Keywords',
  data () {
    return {
      searchForm: {
        companyName: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      loadingImport: false
    }
  },
  methods: {
    initData () {
      this.handleSearch()
    },
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.companyName) {
        params.companyName = this.searchForm.companyName
      }
      DouyinLanvApi.getCompanyList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleBeforeUpload (file) {
      this.loadingImport = true
      const data = new FormData()
      data.append('file', file)
      DouyinLanvApi.importKeywords(data).then(() => {
        this.loadingImport = false
        this.$message.success('导入成功')
        this.handleCurrentChange(1)
      }).catch(() => {
        this.loadingImport = false
      })
      return false
    },
    handleDownloadTpl () {
      window.open('http://file.suketong.cn/SKT-%E5%85%AC%E5%8F%B8%E6%A8%A1%E7%89%88.xlsx')
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less">
.upload-demo {
  display: inline-block;
  margin-left: 10px;
}
</style>
