<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-tabs v-model="activeTab" @tab-click="handleTabsClick">
          <el-tab-pane label="蓝V数据" name="1">
            <lanv ref="table1"></lanv>
          </el-tab-pane>
          <el-tab-pane label="关键词" name="2">
            <keywords ref="table2"></keywords>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import Keywords from './keywords'
import Lanv from './lanv'

export default {
  name: 'DouyinLanv',
  components: {
    Keywords,
    Lanv
  },
  data () {
    return {
      activeTab: '1'
    }
  },
  methods: {
    handleTabsClick (item) {
      this.$refs[`table${item.name}`].handleSearch()
    }
  },
  created: function () {
  }
}
</script>

<style>
</style>
