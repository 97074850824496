<template>
  <div>
    <page-header></page-header>
    <div class="layout-main-body">
      <el-card shadow="never" v-loading="pageLoading">
        <div class="order">
          <div class="info mb10">订单提交成功，请尽快完成付款！订单号：<span>{{ orderInfo.orderNo }}</span></div>
          <div class="amount mb10">应付金额：<span class="money">{{ orderInfo.totalAmount }}</span> 元</div>
          <div class="card" v-if="this.orderInfo.orderType === 2">{{ orderInfo.goodsCount }} 张VIP年卡</div>
        </div>
        <div>
          <h3>请选择支付方式</h3>
        </div>
        <div class="pay-channel-list">
          <div class="pay-channel-item" :class="{'pay-channel-item-active': currentPayWay === 2}"
               @click="handleChangePayWay(2)" v-if="this.orderInfo.orderType === 2">
            <div class="pay-channel-item-check">
              <i class="el-icon-check"></i>
            </div>
            <div class="pay-channel-info">
              <img src="@/assets/images/balance.svg" class="pay-channel-icon">
              <span>余额支付</span>
            </div>
          </div>
          <div class="pay-channel-item" :class="{'pay-channel-item-active': currentPayWay === 1}"
               @click="handleChangePayWay(1)">
            <div class="pay-channel-item-check">
              <i class="el-icon-check"></i>
            </div>
            <div class="pay-channel-info">
              <img src="@/assets/images/wechatpay.svg" class="pay-channel-icon">
              <span>微信支付</span>
            </div>
          </div>
        </div>
        <div class="pay-detail">
          <div class="pay-wechat" v-if="currentPayWay === 1" v-loading="qrCodeLoading">
            <img :src="qrCodeImage" class="qr-image">
            <div>打开微信扫一扫进行支付</div>
          </div>
          <div class="pay-balance" v-if="currentPayWay === 2">
            <div class="pay-balance-item">当前余额：<span class="money">{{ currentAccount.balance || 0 }}</span> 元 <span
                v-if="!balanceIsEnough" class="tips">余额不足，请先充值</span>
            </div>
            <div class="pay-balance-item">
              <el-button type="primary" :disabled="!balanceIsEnough" @click="handleBalancePay"
                         :loading="balancePaySubmitLoading">立即支付
              </el-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>

import { AccountApi, AccountOrderApi } from '@/api'
import Dictionary from '@/utils/dictionary'

export default {
  name: 'OrderPay',
  data () {
    return {
      orderId: '',
      orderInfo: {},
      currentAccount: {},
      pageLoading: false,
      currentPayWay: Dictionary.amsPayWay.balance.code,
      qrCodeImage: null,
      orderTimer: null,
      balancePaySubmitLoading: false,
      qrCodeLoading: false
    }
  },
  computed: {
    balanceIsEnough () {
      const balance = Number(this.currentAccount.balance) * 100
      const totalAmount = Number(this.orderInfo.totalAmount) * 100
      return balance >= totalAmount
    }
  },
  methods: {
    async initData () {
      this.orderId = this.$route.query.id
      this.pageLoading = true
      this.orderInfo = await AccountOrderApi.getOrderInfo(this.orderId)
      this.currentAccount = await AccountApi.getAccount()
      // 判断订单类型，如果是充值订单，则只有在线支付，没有余额支付
      if (this.orderInfo.orderType === Dictionary.amsOrderType.rechargeMoney.code) {
        this.handleChangePayWay(1)
      } else {
        this.handleChangePayWay(2)
      }
      this.pageLoading = false
    },
    handleChangePayWay (val) {
      this.currentPayWay = val
      this.stopStatusTimer()
      if (val === 1) {
        if (!this.qrCodeImage) {
          this.getOrderPayQrCode()
        } else {
          this.startStatusTimer()
        }
      }
    },
    getOrderPayQrCode () {
      this.qrCodeLoading = true
      AccountOrderApi.getOrderPayQrCode({ orderId: this.orderId }).then(res => {
        this.qrCodeLoading = false
        this.qrCodeImage = res
        this.startStatusTimer()
      }).catch(res => {
        this.qrCodeLoading = false
        if (res.status === 10201) {
          this.$confirm('订单支付超时，已自动取消，请重新下单').then(() => {
            this.$router.back()
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    startStatusTimer () {
      this.orderTimer = setInterval(() => {
        console.log(this.orderInfo.orderStatus, Dictionary.amsOrderStatus.done.code, Dictionary.amsOrderPayStatus.success.code)
        if (this.orderInfo.orderStatus === Dictionary.amsOrderStatus.done.code && this.orderInfo.payStatus === Dictionary.amsOrderPayStatus.success.code) {
          this.stopStatusTimer()
          setTimeout(() => {
            this.$router.replace('/account/order/pay/success?source=2')
          }, 1000)
        } else {
          AccountOrderApi.getOrderInfo(this.orderId).then(res => {
            this.orderInfo = res
          })
        }
      }, 1000)
    },
    stopStatusTimer () {
      clearInterval(this.orderTimer)
      this.orderTimer = null
    },
    handleBalancePay () {
      if (!this.balanceIsEnough) {
        this.$message.error('余额不足，请先充值')
        return
      }
      this.balancePaySubmitLoading = true
      AccountOrderApi.payOrderByBalance({ orderId: this.orderId }).then(() => {
        this.balancePaySubmitLoading = false
        this.$router.push('/account/order/pay/success?source=2')
      })
    }
  },
  created: function () {
    this.initData()
  },
  destroyed () {
    this.stopStatusTimer()
  }
}
</script>

<style scoped lang="less">
.money {
  font-size: 20px;
  font-weight: bold;
}

.order {
  font-size: 14px;
  color: #333333;
  margin-bottom: 40px;
}

.pay-channel-list {
  display: flex;
}

.pay-channel-item {
  min-width: 40px;
  height: 48px;
  padding: 0 14px;
  margin-right: 12px;
  margin-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-bottom-right-radius: 2px;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #e3e2e2;
}

.pay-channel-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pay-channel-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.pay-channel-item-check {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 18px solid #f95959;
  border-left: 24px solid transparent;
}

.el-icon-check {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  bottom: -13px;
  color: #fff8ed;
}

.pay-channel-item-active {
  border: 1px solid #f95959;

  .pay-channel-item-check {
    display: block;
  }
}

.pay-detail {
  margin-top: 40px;
  font-size: 14px;

  .pay-wechat {
    text-align: center;
    margin-bottom: 40px;

    .qr-image {
      width: 200px;
      height: 200px;
    }

  }

  .pay-balance {
    .pay-balance-item {
      margin-bottom: 20px;
    }

    .tips {
      color: #f95959;
    }
  }
}

</style>
