<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true" :model="searchForm">
          <el-form-item>
            <el-input v-model="searchForm.text" placeholder="关键词" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.label" placeholder="标签" clearable>
              <el-option v-for="item in tags" :key="item" :value="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="handleCurrentChange(1)">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="rows"
            v-loading="loading"
            style="width: 100%">
          <template slot="empty">
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column type="index" width="55" label="#"></el-table-column>
          <el-table-column prop="keyword" label="关键词" width="300"></el-table-column>
          <el-table-column prop="companyName" label="蓝V企业" width="300"></el-table-column>
          <el-table-column prop="likeCount" label="获赞数">
            <template slot-scope="scope">
              <span>{{ getNumberText(scope.row.likeCount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="followerCount" label="粉丝数">
            <template slot-scope="scope">
              <span>{{ getNumberText(scope.row.followerCount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="uniqueId" label="抖音号" width="140"></el-table-column>
          <el-table-column prop="label" label="标签"></el-table-column>
          <el-table-column prop="creatorName" label="创建人"></el-table-column>
          <el-table-column prop="creationTime" label="创建时间" width="140"></el-table-column>
          <el-table-column
              label="操作"
              width="140">
            <template slot-scope="scope">
              <el-button @click="handleRemove([scope.row.id])" type="text">删除</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-dropdown @command="cmd => handleChangeTag(scope.row.id, cmd)">
                <span class="el-dropdown-link" style="color: #fdac1e;cursor: pointer">
                  标记为...
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in tags" :key="item" :command="item">{{ item }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10,20,50,100]"
            :page-size="size"
            :background="true"
            layout="total, sizes, prev, pager, next"
            :total="total">
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { DouyinLanvApi } from '@/api'
import numeral from 'numeral'

export default {
  name: 'Lanv',
  data () {
    return {
      searchForm: {
        keyword: '',
        label: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      loadingImport: false,
      tags: [
        '无效客户',
        '有效客户',
        '意向客户',
        '已联系'
      ]
    }
  },
  methods: {
    initData () {
      this.handleSearch()
    },
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.keyword) {
        params.keyword = this.searchForm.keyword
      }
      if (this.searchForm.label) {
        params.label = this.searchForm.label
      }
      DouyinLanvApi.getList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleRemove (ids) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        DouyinLanvApi.removeLanv(ids).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {})
    },
    handleChangeTag (id, tag) {
      console.log(id, tag)
      DouyinLanvApi.updateLabel({
        id: id,
        label: tag
      }).then(() => {
        this.$message.success('操作成功')
        this.handleCurrentChange(1)
      })
    },
    getNumberText (val) {
      return numeral(val).format('0,0')
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style>
</style>
