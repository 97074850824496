<template>
  <div>
    <page-header fixed>
      <template v-slot:right>
        <el-button type="primary" @click="handlePublish" :loading="loadingPublish">发布</el-button>
        <el-button plain @click="handleSave" :loading="loadingEdit">保存草稿</el-button>
        <!--          <el-button type="default" @click="gotoEditPage()">继续新增</el-button>-->
        <el-button plain @click="handleParseContent()">内容解析</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-row :gutter="12" v-loading="loading">
        <el-col :span="16">
          <el-card shadow="never" style="min-height: 760px">
            <div class="editor-wrapper">
              <tinymce-editor :init="editorInit" v-model="editForm.content" ref="content"></tinymce-editor>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never" style="min-height: 760px">
            <el-form :model="editForm" label-width="80px">
              <el-form-item prop="categories" label="标题">
                <el-input type="textarea" :rows="6" v-model="editForm.title" placeholder="请输入文章标题" clearable></el-input>
              </el-form-item>
              <el-form-item prop="categories" label="分类">
                <category-select v-model="editForm.categories"></category-select>
              </el-form-item>
              <el-form-item label="标签">
                <dynamic-tag v-model="editForm.tags"></dynamic-tag>
              </el-form-item>
              <el-form-item label="缩略图">
                <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :limit="1"
                    :before-upload="handleHoverBeforeUpload">
                  <img v-if="editForm.coverUrl" :src="editForm.coverUrl" class="avatar"/>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="发布时间">
                <el-date-picker
                    v-model="editForm.publishTime"
                    type="datetime"
                    :picker-options="pickerOptions"
                    default-time="07:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="发布时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="来源">
                <el-select v-model="editForm.source" placeholder="来源" class="select-source"
                           @change="handleSourceChange">
                  <el-option :value="1" label="原创"></el-option>
                  <el-option :value="2" label="转载"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="原文链接" v-if="editForm.source === 2">
                <el-input v-model="editForm.originalLink" placeholder="请输入原文链接" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="editForm.isTop">置顶</el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="将素材添加到推送列表，需要配合发布时间使用，系统默认每天08:00给用户推送消息，需要将发布时间设置为08:00以前">
                  <el-checkbox v-model="editForm.isPush" slot="reference">推送&nbsp;<i
                      class="el-icon-warning-outline"></i>
                  </el-checkbox>
                </el-popover>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { CmsArticleApi, CommonQiniuApi } from '@/api'
import { MATERIAL_SOURCE, MATERIAL_STATUS } from '@/utils/contants'
import Editor from '@tinymce/tinymce-vue'
// A theme is also required
import 'tinymce/themes/silver'
import 'tinymce/plugins/image' // 插入上传图片插件
import 'tinymce/plugins/media' // 插入视频插件
import 'tinymce/plugins/table' // 插入表格插件
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/wordcount' // 字数统计插件
import 'tinymce/plugins/code'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/link'
import 'tinymce/plugins/codesample'
import moment from 'moment'

const defaultForm = () => {
  return {
    id: null,
    title: '',
    coverUrl: '',
    content: '请输入内容',
    tags: [],
    categories: [],
    isTop: false,
    originalLink: null,
    source: MATERIAL_SOURCE.reprint,
    status: MATERIAL_STATUS.publish,
    publishTime: moment().add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
    isPush: false
  }
}
export default {
  name: 'ArticleEdit',
  components: {
    tinymceEditor: Editor
  },
  data () {
    return {
      editForm: defaultForm(),
      loading: false,
      loadingPublish: false,
      loadingEdit: false,
      currentArticleId: '',
      editorInit: {
        language_url: '/lib/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/lib/tinymce/skins/ui/oxide',
        min_height: 700,
        width: 540,
        content_css: '/lib/tinymce/my.css',
        plugins: 'lists image media table wordcount code link preview fullscreen codesample',
        toolbar: 'preview bold italic underline strikethrough link | forecolor backcolor alignleft aligncenter | blockquote code bullist numlist | codesample image media table | formatselect removeformat',
        font_formats: '',
        branding: false,
        menubar: false,
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          const data = new FormData()
          data.append('file', blobInfo.blob())
          CommonQiniuApi.upload(data).then(result => {
            success(result.fileUrl)
          })
        }
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < moment().add(-1, 'd').toDate()
        }
      },
      tinymceKey: 1
    }
  },
  methods: {
    initData () {
      const articleId = this.$route.query.id
      this.currentArticleId = articleId
      this.editForm = defaultForm()
      this.getItem(articleId)
    },
    getItem (articleId) {
      if (articleId) {
        this.loading = true
        CmsArticleApi.getArticle(articleId).then((result) => {
          this.loading = false
          this.editForm = Object.assign({}, result)
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.editForm = defaultForm()
      }
    },
    handleHoverBeforeUpload (file) {
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.upload(data).then((result) => {
        this.editForm.coverUrl = result.fileUrl
      })
      return false
    },
    handleSourceChange (val) {
      if (val === MATERIAL_SOURCE.original) {
        this.editForm.originalLink = null
      }
    },
    valid () {
      if (!this.editForm.title || this.editForm.title.length === 0) {
        this.$message.error('请输入文章标题')
        return false
      }
      if (!this.editForm.content || this.editForm.content.length === 0) {
        this.$message.error('请输入文章内容')
        return false
      }

      if (this.editForm.categories.length === 0) {
        this.$message.error('请选择分类')
        return false
      }
      return true
    },
    async doSave () {
      const data = await CmsArticleApi.saveArticle(this.editForm)
      this.editForm = Object.assign({}, data)
    },
    handlePublish () {
      if (!this.valid()) {
        return
      }
      if (!this.editForm.publishTime || this.editForm.title.publishTime === 0) {
        this.$message.error('请选择发布时间')
        return false
      }

      this.editForm.status = MATERIAL_STATUS.publish
      this.loadingPublish = true
      this.doSave().then(() => {
        this.$message.success('发布成功')
        this.loadingPublish = false
      }).catch(() => {
        this.loadingPublish = false
      })
    },
    handleSave () {
      if (!this.valid()) {
        return
      }
      this.editForm.status = MATERIAL_STATUS.saved
      this.loadingEdit = true
      this.doSave().then(() => {
        this.$message.success('保存成功')
        this.loadingEdit = false
      }).catch(() => {
        this.loadingEdit = false
      })
    },
    handleGotoEditPage () {
      this.$router.replace({
        path: '/refresh',
        query: {
          id: '',
          t: Date.now()
        }
      })
    },
    handleParseContent () {
      this.$confirm('内容解析会从原链接重新抓取内容，并覆盖当前内容，继续吗？', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在解析内容，请稍等'
        })
        CmsArticleApi.parseContent(this.currentArticleId).then(() => {
          loading.close()
          this.getItem(this.currentArticleId)
          this.$message.success('解析成功')
        }).catch(() => {
          loading.close()
        })
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less">
.article {

  .card-tool-panel {
    margin-bottom: 10px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.editor-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
