<template>
  <div class="layout-admin">
    <el-container direction="vertical">
      <app-header></app-header>
      <el-container>
        <el-aside width="208px">
          <el-menu
              router
              class="layout-menu"
              :default-active="activePath">
            <el-menu-item :key="item.id" v-for="item in menus" :index="item.path">
              <template slot="title">
                <div class="title-row">
                  <div class="menu-icon">
                    <i :class="item.icon" class="layout-menu-icon"></i>
                  </div>
                  <div class="menu-title">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <app-main></app-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import AppHeader from '@/layout/components/AppHeader'
import AppMain from '@/layout/components/AppMain'

export default {
  name: 'AccountIndex',
  components: {
    AppHeader,
    AppMain
  },
  data () {
    return {
      menus: [
        {
          id: '0',
          title: '首页',
          path: '/',
          icon: 'fa-solid fa-house'
        },
        {
          id: '1',
          title: '帐户信息',
          path: '/account/info',
          icon: 'fa-solid fa-wallet'
        },
        {
          id: '2',
          title: '我的订单',
          path: '/account/order',
          icon: 'fa-solid fa-table-list'
        },
        {
          id: '3',
          title: '银行卡',
          path: '/account/bank/card',
          icon: 'fa-solid fa-money-check'
        },
        {
          id: '4',
          title: '余额明细',
          path: '/account/balance/log',
          icon: 'fa-solid fa-money-check-dollar'
        },
        {
          id: '5',
          title: 'VIP年卡明细',
          path: '/account/vip/log',
          icon: 'fa-solid fa-list-ul'
        },
        {
          id: '6',
          title: '提现记录',
          path: '/account/withdraw/list',
          icon: 'fa-solid fa-circle-dollar-to-slot'
        }
      ]
    }
  },
  computed: {
    activePath () {
      return this.$route.path
    }
  },
  methods: {},
  mounted () {
  }
}
</script>

<style lang="less" scoped>

</style>
