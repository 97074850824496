<template>
  <div>
    <div class="user">
      <div class="user-info">
        <div class="user-info-text">
          <h3>欢迎您，{{ userInfo.displayName }}，祝您开心每一天！</h3>
        </div>
        <div class="user-info-dept">
          <span>{{ userInfo.deptName }}</span>
        </div>
      </div>
      <div class="user-report">
        <div class="user-report-item">
          <div class="title">总用户</div>
          <div class="text" v-html="reportTotal.total" @click="handleGotoUserPage(0)">-</div>
        </div>
        <div class="user-report-item">
          <div class="title">免费用户</div>
          <div class="text" v-html="reportTotal.totalOrdinary" @click="handleGotoUserPage(1)">-</div>
        </div>
        <div class="user-report-item">
          <div class="title">VIP(一年/三年)</div>
          <div class="text">
            <span class="text" v-html="reportTotal.totalV1" @click="handleGotoUserPage(2)">-</span>
            <span>/</span>
            <span class="text" v-html="reportTotal.totalV3" @click="handleGotoUserPage(3)">-</span>
          </div>
        </div>
        <el-divider direction="vertical" class="line"></el-divider>
        <div class="user-report-item">
          <div class="title">即将到期（三个月）</div>
          <div class="text" v-html="reportTotal.totalExpire" @click="handleGotoUserPage(4)">-</div>
        </div>
        <el-divider direction="vertical" class="line"></el-divider>
        <div class="user-report-item">
          <div class="title">销售额</div>
          <div class="text">{{ reportTotal.totalAmount | money }}</div>
        </div>
      </div>
    </div>
    <div class="layout-main-body">
      <el-row>
        <el-col>
          <sales-result-card data-type="user" title="我的业绩"></sales-result-card>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <shortcut-card></shortcut-card>
        </el-col>
        <el-col :span="12">
          <doc-card></doc-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import { getCurrentMonthDate } from '@/utils/date-utils'
import { SHORTCUTS } from '@/utils/contants'
import { RptApi } from '@/api'
import SalesResultCard from './components/SalesResultCard'
import DocCard from './components/DocCard'
import ShortcutCard from './components/ShortcutCard'

const defaultReportTotal = () => {
  return {
    total: 0,
    totalVip: 0,
    totalOrdinary: 0,
    totalV1: 0,
    totalV3: 0,
    totalExpire: 0,
    totalAmount: 0
  }
}

export default {
  name: 'Home',
  components: {
    SalesResultCard,
    DocCard,
    ShortcutCard
  },
  data () {
    return {
      deptList: [],
      searchForm: {
        size: 20,
        page: 1
      },
      rows: [],
      total: 0,
      loading: false,
      dateRadio: 2,
      dateRange: getCurrentMonthDate(),
      pickerOptions: {
        shortcuts: SHORTCUTS
      },
      reportTotal: defaultReportTotal()
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.info
    }
  },
  methods: {
    initData () {
      RptApi.getUserCountTotalReport({ userId: this.userInfo.id }).then((res) => {
        this.reportTotal = res || defaultReportTotal()
      })
    },
    handleGotoUserPage (type) {
      this.$router.push(`/user/list?type=${type}`)
    }
  },
  created () {
    this.initData()
  }
}
</script>

<style scoped lang="less">

.user {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  padding: 10px;

  .user-info {
    flex: 1;

    .user-info-dept {
      color: #999999;
      font-size: 14px;
    }
  }

  .user-report {
    display: flex;
    align-items: center;

    .user-report-item {
      padding: 10px 20px;
      font-size: 16px;

      .title {
        color: #999999;
        font-size: 14px;
        text-align: center;
      }

      .text {
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
        cursor: pointer;
      }
    }

    .line {
      height: 2em;
    }
  }
}

.condition-panel {
  display: flex;
  align-items: center;

  .condition-item {
    margin-right: 10px;
  }

  .date-range {
    width: 262px;
  }

  .dept-select {
    width: 200px;
  }
}

.top-row {
  color: black;
  font-weight: bold;
}

</style>
