<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    start-placeholder="注册时间起"
                    end-placeholder="注册时间止"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="searchForm.isReg">只显示有手机号的</el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-button plain @click="handleSearch">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" width="55" label="#"></el-table-column>
              <el-table-column prop="nickName" label="昵称" width="300"></el-table-column>
              <el-table-column prop="mobile" label="手机号" width="100"></el-table-column>
              <el-table-column prop="creationTime" label="注册时间" width="140"></el-table-column>
              <el-table-column prop="source" label="注册来源"></el-table-column>
              <el-table-column prop="companyName" label="公司名称" width="200"></el-table-column>
              <el-table-column prop="companyAddress" label="地址" width="400"></el-table-column>
              <el-table-column
                  label="操作"
                  fixed="right"
                  width="80">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleBindUser(scope.row.id)">绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserApi } from '@/api'
import { SHORTCUTS } from '@/utils/contants'

export default {
  name: 'UserPublicList',
  data () {
    return {
      searchForm: {
        registerTimeStart: '',
        registerTimeEnd: '',
        isReg: true
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      dialogVisible: false,
      pickerOptions: {
        shortcuts: SHORTCUTS
      },
      dateRange: []
    }
  },
  methods: {
    handleSearch () {
      this.loading = true
      const params = {
        ...this.searchForm,
        page: this.page - 1,
        size: this.size
      }
      if (this.dateRange.length === 2) {
        params.registerTimeStart = this.dateRange[0]
        params.registerTimeEnd = this.dateRange[1]
      }

      UserApi.getPublicUsers(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleBindUser (userId) {
      this.$confirm('确定要绑定该用户吗？', '提示', {
        type: 'warning'
      }).then(() => {
        UserApi.bindUser({ memberId: userId }).then(() => {
          this.handleCurrentChange(1)
          this.$message.success('绑定成功')
        }).catch(() => {
          this.handleCurrentChange(1)
          this.$message.success('服务繁忙，请稍后重试')
        })
      }).catch(() => {
      })
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style lang="less">

.user-search-result {
  margin-top: 10px;
}

</style>
